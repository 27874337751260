<template>
  <div id="CompareVehicles" ref="compareView">
    <h1 v-show="false">
      Electric Vehicle Models Recommendation Matching Your Needs
    </h1>
    <div id="ComparisonHeader" class="d-none d-lg-block">
      <compare-header />
      <div class="mt-1 mb-0">
        <search-criteria-nav></search-criteria-nav>
      </div>
    </div>
    <div id="ComparisonContainer">
      <div v-if="vehiclesToCompare.length > 0">
        <div id="Vehicle-Summary-Desktop" class="d-none d-lg-block px-5">
          <div id="images" class="row">
            <div class="col" v-for="(vehicle, $positionIndex) in vehiclesToCompare" :key="vehicle.Id">
              <compare-vehicle-title :vehicle="vehicle" />

              <div class="row">
                <div class="col">
                  <compare-vehicle-nav @navigate:left="previousVehicle($positionIndex)"
                    @navigate:right="nextVehicle($positionIndex)" @chooseVehicle="openAddVehicleModal($positionIndex)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="Vehicle-Summary-Mobile" class="d-lg-none container-fluid">
          <div id="Vehicle-Image-Carousel-Container" class="row">
            <div class="col-12 px-0 mx-0">
              <b-carousel id="carousel" :interval="5000" :controls="true">
                <!-- Text slides with image -->
                <b-carousel-slide v-for="vehicle in vehiclesToCompare" :key="vehicle.Id">
                  <template v-slot:img>
                    <compare-vehicle-title :vehicle="vehicle" />
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
          <div id="Match-Count" class="row pb-0">
            <div class="col">
              <h3>Best match vehicles for you.</h3>
              <p>Total found: {{ matchCount }}</p>
            </div>
          </div>
          <div id="Vehicle-Image-Compare" class="row" :class="{ scrolled: hasScrolled }">
            <div class="col-4" v-for="(vehicle, $positionIndex) in vehiclesToCompare" :key="vehicle.Id"
              :class="['rank', getMatchClass(vehicle)]">
              <div class="row">
                <div class="col-12">
                  <a href="#" target="_blank" class="vehicle-image-link" @click.prevent="goToDetail(vehicle)">
                    <VehicleImage class="img-fluid" :vehicle="vehicle" imgType="exterior" imgSizeType="Small"
                      @image-not-found="imgNotFound($event, image)" />
                  </a>
                </div>
              </div>
              <div class="row pt-1">
                <div class="col-12">
                  <span class="match-text">{{ getMatchText(vehicle) }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-auto pr-1" v-show="vehicle.rank">
                  <div class="rank-icon">{{ vehicle.rank }}</div>
                </div>
                <div class="col" :class="{ 'pl-0': vehicle.rank }">
                  <YMMS :vehicle="vehicle" />
                  <br />
                  <div class="row">
                    <div class="col">
                      <router-link class="learn-more-link" :to="`/detail/${vehicle.YMMS_Desc}`">
                        learn more &raquo;
                      </router-link>
                    </div>
                  </div>
                  <a class="choose-vehicle mobile" href=""
                    @click.prevent="openAddVehicleModal($positionIndex)"><u>change&nbsp;<span
                        class="fas fa-exchange-alt"></span></u></a>
                </div>
              </div>
              <div class="row text-left">
                <div class="col pl-2"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <compare-row :vehicles="vehiclesToCompare" :feature="getFeature('CompareSummary')" :location="location"
            class="px-3" @navigate="goToDetail($event)" />
        </div>
        <div class="container-fluid">
          <compare-row v-for="(feature, idx) in features" :key="idx" :vehicles="vehiclesToCompare" :feature="feature"
            :location="location" @navigate="goToDetail($event)" />
        </div>
        <div id="AccuracyFooter" class="container-fluid py-4">
          <div class="row">
            <div class="col-12 col-lg-6 ml-4">
              <AccuracyDisclaimer />
            </div>
          </div>
        </div>

      </div>
    </div>
    <b-modal id="tooltip-modal">
      <div id="tooltip-content" v-html="tooltipText"></div>
    </b-modal>
    <div class="row">
      <div class="col-6 text-center">
        <b-modal id="AddVehicleModal" size="lg" variant="dark" :scrollable="true" hide-footer hide-header
          @hide="resetAddVehicleModal()">
          <div class="container-fluid">
            <div id="ChooseYMMS">
              <div class="row">
                <button class="close text-right" type="button" @click="$bvModal.hide('AddVehicleModal')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 header">
                      <p>
                        Choose a different vehicle by YMMS or browse vehicles
                        that are similar to your other matched vehicles
                      </p>
                      <h3>CHOOSE A SPECIFIC EV</h3>
                    </div>
                  </div>
                  <VehicleYMMSSelector v-model="vehicleToAdd" />
                  <div class="row text-right">
                    <div class="col-12">
                      <button class="btn button" @click="addVehicle()">
                        select &raquo;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3" v-show="addVehicleErrorMessage">
                <div class="col-12">
                  <b-alert show variant="warning">
                    {{ addVehicleErrorMessage }}
                  </b-alert>
                </div>
              </div>
            </div>
            <div id="GoToVehicle">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="row header">
                    <div class="col-12">
                      <h3>BROWSE MATCHED VEHICLES</h3>
                      <p>These vehicles are similar to your matched vehicles</p>
                    </div>
                  </div>
                  <div id="GoToVehicleOptions">
                    <div class="row vehicle-nav-option" v-for="(
                            vehicleIndex, $idx
                          ) in indexesOfAvailableCompareVehicles" :key="$idx">
                      <div class="form-check col-12">
                        <div class="row">
                          <div class="col-2 col-md-1 text-right">
                            <input :id="'RadioOption' + $idx" :name="'RadioOption' + $idx" type="radio"
                              :value="vehicleIndex" class="form-check-input" v-model="vehicleIndexToGoTo" />
                          </div>
                          <label :for="'RadioOption' + $idx" class="form-check-label col-10 col-md-8">
                            <p class="ymms">
                              <span class="year">{{ allVehicles[vehicleIndex].Year }}
                              </span>
                              <span class="make">{{ allVehicles[vehicleIndex].Make }}
                              </span>
                              <span class="model">{{ allVehicles[vehicleIndex].Model }}
                              </span>
                              <span class="trim">{{
                                allVehicles[vehicleIndex].Trim
                              }}</span>
                            </p>
                            <p>
                              <b>MSRP</b> from:
                              {{
                                toCurrency(allVehicles[vehicleIndex].Price, 0)
                              }}
                            </p>
                          </label>
                          <div class="col-3 text-right d-none d-md-block">
                            <VehicleImage :vehicle="allVehicles[vehicleIndex]" imgType="exterior"
                              imgSizeType="Thumbnail" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right mt-3">
                      <button class="btn button" @click="goToVehicle(vehicleIndexToGoTo)">
                        select &raquo;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>

  </div>
</template>

<script>
import ElectricDriverApi from "Services/ElectricDriverApi";
import { FeatureMap } from "Services/FeatureMap";
import common from "Mixins/common";
const AccuracyDisclaimer = () => import("../components/AccuracyDisclaimer");
const CompareHeader = () => import("Components/Compare/CompareHeader");
const CompareRow = () => import("Components/Compare/CompareRow");
const VehicleYMMSSelector = () => import("Components/VehicleYMMSSelector");
const CompareVehicleTitle = () =>
  import("Components/Compare/CompareVehicleTitle");
const CompareVehicleNav = () => import("Components/Compare/CompareVehicleNav");
const SearchCriteriaNav = () => import("Components/SearchCriteriaNav");
const VehicleImage = () => import("Components/VehicleImage");
const YMMS = () => import("Components/YMMS");
export default {
  metaInfo: {
    title: "Electric cars comparisons and tradeoffs",
    meta: [
      {
        name: "description",
        content:
          "Our comparison tool helps you shortlist electric cars that best fit your needs and easily understand the tradeoffs between candidates.",
      },
    ],
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("touchend", this.handleScroll);
    this.initialize();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("touchend", this.handleScroll);
  },
  data() {
    return {
      vehicleToAdd: null,
      addVehicleErrorMessage: "",
      indexesOfCompareVehicles: [],
      allVehicles: [],
      tooltipText: "",
      vehicleIndexToGoTo: 0,
      positionOfVehicleToReplace: 0,
      scrollY: 0,
      screenWidth: 0,
      screenHeight: 0,
      contentHeight: 0
    };
  },
  methods: {
    initialize() {
      this.getRecommendations();
      this.$store.dispatch("fetchAllYMMS");
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      this.screenWidth = window.outerWidth;
      this.screenHeight = window.outerHeight
      this.contentHeight = this.$refs.compareView.clientHeight
    },
    async addVehicle() {
      if (this.vehicleToAdd) {
        if (!this.allVehicles.find((x) => x.Id === this.vehicleToAdd.Id)) {
          const vehicle = await ElectricDriverApi.getVehicleById(
            this.vehicleToAdd.Id
          );
          this.allVehicles.push(vehicle);
          this.$set(
            this.indexesOfCompareVehicles,
            this.positionOfVehicleToReplace,
            this.allVehicles.length - 1
          );
          this.$bvModal.hide("AddVehicleModal");
        } else {
          this.addVehicleErrorMessage =
            "This vehicle is already in your vehicle list";
        }
      }
    },
    goToVehicle($idx) {
      this.$set(
        this.indexesOfCompareVehicles,
        this.positionOfVehicleToReplace,
        $idx
      );
      this.$bvModal.hide("AddVehicleModal");
    },
    openAddVehicleModal($positionIndex) {
      this.vehicleIndexToGoTo = this.indexesOfAvailableCompareVehicles.length
        ? this.indexesOfAvailableCompareVehicles[0]
        : 0;
      this.positionOfVehicleToReplace = $positionIndex;
      this.$bvModal.show("AddVehicleModal");
    },
    resetAddVehicleModal() {
      this.addVehicleErrorMessage = "";
    },
    getRecommendations() {
      return this.$store.dispatch("loadComparisonData").then(() => {
        this.updateVehicles();
      });
    },
    updateVehicles() {
      this.allVehicles = [...this.recommendedVehicles];
      this.allVehicles.sort(
        (a, b) =>
          (a.Match_Rating - b.Match_Rating ||
            b.Total_Score - a.Total_Score ||
            b.Price - a.Price ||
            b.Year - a.Year)
      );
      this.allVehicles = this.allVehicles.map((v) => ({
        ...v,
        rank: this.allVehicles.indexOf(v) + 1,
      }));
      this.indexesOfCompareVehicles = [
        ...Array(
          this.allVehicles.length < 3 ? this.allVehicles.length : 3
        ).keys(),
      ];
    },
    nextVehicle($positionIndex) {
      const vehicleIndex = this.indexesOfCompareVehicles[$positionIndex];
      const largerIndexes = this.indexesOfAvailableCompareVehicles.filter(
        (x) => x > vehicleIndex
      );
      const nextIndex =
        largerIndexes.length > 0
          ? Math.min(...largerIndexes)
          : this.indexesOfAvailableCompareVehicles[0];
      this.$set(this.indexesOfCompareVehicles, $positionIndex, nextIndex);
    },
    previousVehicle($positionIndex) {
      const vehicleIndex = this.indexesOfCompareVehicles[$positionIndex];
      const smallerIndexes = this.indexesOfAvailableCompareVehicles.filter(
        (x) => x < vehicleIndex
      );
      const previousIndex =
        smallerIndexes.length > 0
          ? Math.max(...smallerIndexes)
          : this.indexesOfAvailableCompareVehicles[
          this.indexesOfAvailableCompareVehicles.length - 1
          ];
      this.$set(this.indexesOfCompareVehicles, $positionIndex, previousIndex);
    },
    getFeature(feature) {
      return FeatureMap.find((f) => f.name === (feature?.name || feature));
    },
    goToDetail(vehicle) {
      this.$router.push({
        path: `detail/${vehicle.YMMS_Desc}`,
      });
    },
    getMatchClass(vehicle) {
      if (vehicle.Matched) {
        return vehicle.rank <= 3 ? `rank-${vehicle.rank}` : "rank-4-or-more";
      }

      return "fuzzy-match";
    },
    getMatchText(vehicle) {
      if (vehicle.Matched) {
        return vehicle.rank === 1
          ? "Best Match"
          : vehicle.Total_Score > 0.5
            ? "Great Match"
            : "Match";
      }

      return "Near Match";
    },
  },
  computed: {
    features() {
      return this.$store.getters.selectedFeatureDetails;
    },
    hasScrolled() {
      if (this.contentHeight - this.screenHeight < 500) return false
      return this.screenWidth > 750 ? this.scrollY > 800 : this.scrollY > 500;
    },
    indexesOfAvailableCompareVehicles() {
      const indexes = [...Array(this.allVehicles.length).keys()];
      const result = indexes.filter(
        (x) => !this.indexesOfCompareVehicles.some((y) => y === x)
      );
      return result;
    },
    location() {
      return this.$store.state.locationInfo;
    },
    matchCount() {
      return this.$store.getters.recommendationMatchCount;
    },
    searchOptions() {
      return this.$store.state.searchOptions;
    },
    vehiclesToCompare() {
      const result = [];
      this.indexesOfCompareVehicles.forEach((x) =>
        result.push(this.allVehicles[x])
      );
      return result;
    },
    recommendedVehicles() {
      return this.$store.state.recommendedVehicles;
    }
  },
  watch: {
    recommendedVehicles() {
      this.updateVehicles();
    }
  },
  mixins: [common],
  components: {
    CompareHeader,
    CompareRow,
    CompareVehicleTitle,
    CompareVehicleNav,
    SearchCriteriaNav,
    VehicleImage,
    VehicleYMMSSelector,
    YMMS,
    AccuracyDisclaimer
  },
};
</script>
<style lang="scss" scoped>
@import "~bootstrap";

div {
  color: $primary-font-color-dark;
}

#Vehicle-Image-Compare {
  padding-top: 0;
  padding-bottom: 20px;

  &.scrolled {
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 15px;
    z-index: 1000;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 2px solid #2b82e3 !important;
  }

  ::v-deep .ymms {
    line-height: 0.5rem;

    .year,
    .make,
    .model {
      font-size: 0.7rem;
    }

    .series {
      font-size: 0.5rem;
    }
  }
}

#ComparisonHeader {
  padding: 10px 0px 5px 50px;
}

#ComparisonContainer {
  background-color: white;
}

.fuzzy-results {
  padding-top: 38px;
  padding-bottom: 20px;
}

.rank {
  .match-text {
    font-weight: 800;
  }

  .rank-icon {
    position: relative;
    top: 7px;
    height: 9x;
    width: 9px;
    border-radius: 50%;
    font-size: 7px;
    color: white;
    text-align: center;
  }
}

.rank-1 {
  .match-text {
    color: $best-match;
  }

  .rank-icon {
    background-color: $best-match;
  }
}

.rank-2 {
  .match-text {
    color: $great-match;
  }

  .rank-icon {
    background-color: $great-match;
  }
}

.rank-3 {
  .match-text {
    color: $third-best-color;
  }

  .rank-icon {
    background-color: $third-best-color;
  }
}

.rank-4-or-more {
  .match-text {
    color: #797979;
  }

  .rank-icon {
    background-color: #bdbdbd;
  }
}

.rating {
  font-size: 0.875em;
  font-weight: 1000;
  margin: 0;
  color: $header-font-color;
}

#tooltip-content {
  & ::v-deep h4 {
    font-size: 1em;
  }

  & ::v-deep p {
    font-weight: normal;
    font-size: 0.8em;
  }
}

.row.detail-links {
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url("~Images/blurred_pattern_bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  border-top: 4px solid $primary-bg-color;

  @include media-breakpoint-down(sm) {
    background-color: black;
  }
}

#carousel {
  .carousel-ymms {
    font-size: 0.9rem;
    background: grey;
    color: white;
    right: 4px;
    top: 10px;
    text-align: right;
    margin: 0;
    padding: 5px;
  }

  & ::v-deep .carousel-control-prev-icon {
    position: relative;
    left: -25%;
    top: -75px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2330ca34' viewBox='0 0 8 8' %3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  & ::v-deep .carousel-control-next-icon {
    position: relative;
    right: -25%;
    top: -75px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2330ca34' viewBox='0 0 8 8' %3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }

  & ::v-deep .carousel-indicators li {
    background-color: $primary-bg-color;

    &:not(.active) {
      opacity: 0.25;
    }
  }

  & ::v-deep img {
    margin: auto;
    width: 100%;
  }
}

::v-deep .button.btn {
  max-width: 125px;
  font-size: 0.8rem;
}

.link {
  padding-left: 0;
}

.vehicle-toggle {
  i {
    color: $link-color-dark;
    font-size: 1.2rem;
  }

  .fa-chevron-circle-left {
    padding-right: 0;
  }

  .fa-chevron-circle-right {
    padding-left: 10px;
  }
}

::v-deep .ymms-selector {
  select {
    @extend .form-control, .form-control-sm;
    margin-bottom: 25px;
  }

  option {
    padding-top: 20px;
  }
}

::v-deep .modal .modal-lg {
  width: 800px !important;

  @include media-breakpoint-down(sm) {
    width: 400px !important;
  }
}

#AddVehicleModal {
  .header {

    h3,
    p {
      font-family: $primary-header-font-family;
      color: $tertiary-font-color-dark;
    }
  }

  button {
    width: 100%;
    color: black;
  }

  .vehicle-nav-option {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid $secondary-font-color-dark;

    ::v-deep img {
      border: 2px solid $secondary-font-color-dark;
      max-height: 75px;
      position: relative;
      right: 10px;
    }

    .ymms {
      font-size: 1rem;

      .trim {
        font-size: 0.58;
      }
    }
  }

  #ChooseYMMS {
    padding-bottom: 20px;
  }

  #GoToVehicleOptions {
    line-height: 1;
    max-height: 355px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;

    p {
      color: $tertiary-font-color-dark;
    }

    b {
      color: $primary-font-color-dark;
    }
  }
}

.learn-more-link {
  margin-left: 0;
  text-decoration: underline;

  a {
    font-size: 0.75rem;
    color: #0056b3 !important;
  }
}

.choose-vehicle {
  &.desktop {
    padding-top: 7px;
    padding-left: 50px;
  }

  a {

    .accent,
    &:hover {
      text-decoration: none !important;
    }

    font-size: 0.8rem;

    .accent {
      color: $tertiary-button-accent-color;
      padding-right: 10px;
    }
  }

  &.mobile {
    margin-left: 0;

    a {
      font-size: 0.75rem;

      .accent {
        padding-right: 2px;
      }
    }
  }
}

#Vehicle-Image-Compare {

  .year,
  .make,
  .model,
  .trim {
    font-size: 0.75rem;
  }

  h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  p {
    font-size: 0.75rem;
  }

  .year {
    color: $primary-font-color-dark;
    font-weight: 800;
  }

  .make {
    font-weight: 800;
    color: $tertiary-font-color-dark;
  }

  .model,
  .trim {
    font-weight: normal;
  }

  .trim {
    font-size: 0.5rem;
  }
}

a.vehicle-image-link:hover {
  opacity: 0.5;
}

.compare-vehicle-nav {
  margin: 5px 0 20px 0;
}

#Match-Count {

  h3,
  p {
    color: #333333;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 3px;
  }

  p {
    font-size: 0.8rem !important;
  }
}

#AccuracyFooter {
  background-color: $striped-row-bg-color;
}
</style>
